
#camera, #camera--view, #camera--sensor, #camera--output{
    position: fixed;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#camera--view, #camera--sensor, #camera--output{
    transform: scaleX(-1);
    filter: FlipH;
}

